<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-9 col-12">WhatsApp Campaign Logs<span class="log-header-sublabel ms-3">- {{
      campaigndetails.co2 ? campaigndetails.co2 : "N/A" }}</span></div>
    <div class="
            col-lg-3 col-12
            text-end
            d-flex
            justify-content-end
            custom-flex-cloumn-mob
          ">
      <div class="service-plan-box-outer">
        <div class="d-flex justify-content-between align-items-center">
          <div class="call-label">Count : </div>
          <div class="call-count ps-1">
            {{ totalRecords ? totalRecords : "0" }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-3 col-md-3 col-12">
      <div class="ticket-history-box-outer mb-3">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12">
            <div class="customer-label-group mb-2">
              <label class="form-label">Scheduled at Date & Time</label>
              <div class="from-label-value">
                {{ format_timestamp(campaigndetails.co12) }}
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-12">
            <div class="customer-label-group mb-2">
              <label class="form-label">Scheduled for Date & Time</label>
              <div class="from-label-value">
                {{ format_timestamp(campaigndetails.co5) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-9 col-md-9 col-12">
      <div class="card-template mb-2">
        <div class="row">
          <div class="custom-body-logs-scroll" :class="this.campaigndetails.cg6 || this.campaigndetails.cg8 || this.campaigndetails.cg10 || this.campaigndetails.cg24 || this.campaigndetails.cg26 ? 'col-lg-8 col-md-8 col-12' : 'col-lg-12 col-md-12 col-12'">
            <div class="custom-form-group mb-0 d-flex" >
              <div v-if="campaigndetails.cg15 == 1">
                <div v-if="campaigndetails.cg16 == 1" class="me-3">
                  <img class="img-fluid " :src="campaigndetails.cg18" width="150px" />
                </div>
              </div>
              <div v-if="campaigndetails.cg5"><span v-html="replacebrake(campaigndetails.cg5)"></span></div>
            </div>
          </div>

          <!-- <Divider v-if="campaigndetails.cg6 || campaigndetails.cg8 || campaigndetails.cg10" type="dashed" /> -->
          <div class="col-lg-4 col-md-4 col-12" v-if="this.campaigndetails.cg6 || this.campaigndetails.cg8 || this.campaigndetails.cg10 || this.campaigndetails.cg24 || this.campaigndetails.cg26">
            <div class="custom-form-group mb-0" >
                    <button v-if="campaigndetails.cg6" type="button"
                        class="btn modal-bulk-next-btn w-100 my-1 text-capitalize" disabled><i class="pi pi-reply me-2"
                            style="font-size: 0.7rem;"></i>{{
        campaigndetails.cg6 }}</button>
                    <button v-if="campaigndetails.cg8" type="button"
                        class="btn modal-bulk-next-btn w-100 my-1 text-capitalize" disabled><i class="pi pi-reply me-2"
                            style="font-size: 0.7rem;"></i>{{
        campaigndetails.cg8 }}</button>
                    <button v-if="campaigndetails.cg10" type="button"
                        class="btn modal-bulk-next-btn w-100 my-1 text-capitalize" disabled><i class="pi pi-reply me-2"
                            style="font-size: 0.7rem;"></i>{{
        campaigndetails.cg10 }}</button>
        <button v-if="campaigndetails.cg24" type="button"
                        class="btn modal-bulk-next-btn w-100 my-1 text-capitalize" disabled><i class="pi pi-reply me-2"
                            style="font-size: 0.7rem;"></i>{{
        campaigndetails.cg24 }}</button>
        <div class="">
        <button v-if="campaigndetails.cg26" type="button"
                        class="btn modal-bulk-next-btn w-100 my-1 text-capitalize" disabled><i class="pi pi-reply me-2"
                            style="font-size: 0.7rem;"></i>{{
        campaigndetails.cg26 }}</button>
        </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="custom-ultima-datatable" style="height: calc(100vh - 275px)">
    <DataTable :value="campaignLogList" :scrollable="true" scrollHeight="flex" :paginator="true" :rows="30" :lazy="true"
      :rowHover="true" :totalRecords="totalRecords" @page="changePage($event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      :loading="loading" dataKey="cp1">
      <template v-if="!loading" #empty>No records found.</template>
      <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
      <Column field="name" header="Voter Name" headerStyle="width: 25%" bodyStyle="width: 25%">
        <template #body="{ data }">
          <div>
            <div class="text-capitalize">
              {{ data.cq7 ? data.cq7 : "N/A" }}
            </div>
          </div>
        </template>
      </Column>
      <Column field="votermobile" header="Voter Mobile No." headerStyle="width: 15%" bodyStyle="width: 15%">
        <template #body="{ data }">
          <div>
            {{ data.cq8 ? data.cq8 : "N/A" }}
          </div>
        </template>
      </Column>
      <Column field="statussent" header="Sent" headerStyle="width: 10%" bodyStyle="width: 10%"
        class="d-flex justify-content-center">
        <template #body="{ data }">
          <div>
            <i class="pi pi-check-circle text-success" v-if="data.cq18 == 1" style="fontsize: 1rem"></i>
            <i class="pi pi-times-circle text-danger" v-else style="fontsize: 1rem"></i>
          </div>
        </template>
      </Column>
      <Column field="statusdeliverd" header="Delivered" headerStyle="width: 10%" bodyStyle="width: 10%"
        class="d-flex justify-content-center">
        <template #body="{ data }">
          <div>
            <i class="pi pi-check-circle text-success" v-if="data.cq20 == 1" style="fontsize: 1rem"></i>
            <i class="pi pi-times-circle text-danger" v-else style="fontsize: 1rem"></i>
          </div>
        </template>
      </Column>
      <Column field="statusread" header="Read" headerStyle="width: 10%" bodyStyle="width: 10%"
        class="d-flex justify-content-center">
        <template #body="{ data }">
          <div>
            <i class="pi pi-check-circle text-success" v-if="data.cq22 == 1" style="fontsize: 1rem"></i>
            <i class="pi pi-times-circle text-danger" v-else style="fontsize: 1rem"></i>
          </div>
        </template>
      </Column>
      <Column field="statusbutton" header="Button Name" headerStyle="width: 30%" bodyStyle="width: 30%"
        class="d-flex justify-content-center">
        <template #body="{ data }">
          <div>
            <span class="me-4" v-if="data.cq12 == 1">
              <i class="pi pi-check-circle text-success me-2" style="fontsize: 1rem" v-if="data.cq12 == 1"></i>
              <i class="pi pi-times-circle text-danger me-2" style="fontsize: 1rem" v-else></i>
              <span>{{ data.cq13 }}</span></span>
            <span class="me-4" v-if="data.cq12 == 2">
              <i class="pi pi-check-circle text-success me-2" style="fontsize: 1rem" v-if="data.cq12 == 2"></i>
              <i class="pi pi-times-circle text-danger me-2" style="fontsize: 1rem" v-else></i>
              <span>{{ data.cq14 }}</span></span>
            <span v-if="data.cq12 == 3">
              <i class="pi pi-check-circle text-success me-2" style="fontsize: 1rem" v-if="data.cq12 == 3"></i>
              <i class="pi pi-times-circle text-danger me-2" style="fontsize: 1rem" v-else></i>
              <span>{{ data.cq15 }}</span></span>
            <span v-if="data.cq12 == 4">
              <i class="pi pi-check-circle text-success me-2" style="fontsize: 1rem" v-if="data.cq12 == 4"></i>
              <i class="pi pi-times-circle text-danger me-2" style="fontsize: 1rem" v-else></i>
              <span>{{ data.cq16 }}</span></span>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>
<script>
import ApiService from "../../service/ApiService";
import { useRoute } from "vue-router";
import moment from 'moment';
export default {
  data() {
    return {
      campaignLogList: [],
      campaigndetails: '',
      totalRecords: 0,
      loading: false,
    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },

  mounted() {
    const route = useRoute();
    this.routeParam = route.params.campaignId;
    this.loading = true;
    this.getcampaignlogs({ co1: this.routeParam });
  },
  beforeUnmount() {
    clearInterval(this.timer5);
  },
  methods: {
    getcampaignlogs(item) {
      this.ApiService.getcampaignlogs(item).then((data) => {
        if (data.success === true) {
          this.campaignLogList = data.data;
          this.campaigndetails = data.campaign_details;
          this.totalRecords = data.count;
          this.loading = false;
        } else {
          this.loading = false;
          this.campaignLogList = null;
          this.campaigndetails = '';
          this.totalRecords = 0;
        }
      });
    },
    changePage(event) {
      this.page_no = event.page;
      this.getcampaignlogs({ page_no: this.page_no, co1: this.routeParam });
    },
    toggleMessageInfoList(id, event) {
      this.$refs[id].toggle(event);
    },
    format_timestamp(value) {
      if (value) {
        var localTime = new Date(value * 1000).toUTCString();
        return moment.utc(localTime).format("DD/MM/YYYY @ hh:mm A");
      } else {
        return '-';
      }
    },
    replacebrake(html) {
        const doc = html;
        return doc.replace(/(\r)*\n/g, '<br>')
    },
  },
};
</script>
<style scoped>
.box-icon-inner {
  background: #f2f4fb 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  pointer-events: none;
}

.card-template {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c1d4f3;
  border-radius: 4px;
  padding: 14px 15px 0px 15px;
}

.overlay-reg-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 16px;
  margin-bottom: 6px;
}

.log-header-sublabel {
  font-family: "AcuminPro-SemiBold";
  font-size: 17px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}

.service-plan-box-outer {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #e0e5ed;
  border-radius: 4px;
  padding: 6px 10px;
}

.service-plan-box-outer .call-label {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
  user-select: none;
}

.service-plan-box-outer .call-count {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #4a5463;
  text-align: left;
  line-height: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.modal-bulk-next-btn.btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 10px;
    font-family: "AcuminPro-Regular";
}

.modal-bulk-next-btn:focus {
    box-shadow: none;
}
.custom-body-logs-scroll {
  overflow-y: auto;
    position: relative;
    overflow-x: hidden;
    height: 92px;
    margin-bottom: 10px;
}
.custom-body-logs-scroll::-webkit-scrollbar {
    width: 0.3em !important;
    height: 0.3em !important;
}

  .custom-body-logs-scroll::-webkit-scrollbar-thumb {
    background-color: rgba(31, 30, 30, 0.19) !important;
    border-radius: 3px !important;
}
</style>